import request from '@/utils/Request';

// 区域管理员列表
export function getLimitUserList(params) {
  return request({
    url: `/manage/admin/regional-manager/user`,
    method: 'get',
    params,
  });
}

// 权限信息修改页
export function getUserLimitInfo(user_id) {
    return request({
      url: `/manage/admin/regional-manager/detail/${user_id}`,
      method: 'get',
      params: {
        user_id : user_id,
      },
    });
  }

// 添加/修改用户权限信息
export function saveUserLimitInfo(params) {
  if(params.user_id){
    return request({
      url: `/manage/admin/regional-manager/store/${params.user_id}`,
      method: 'post',
      params,
    });
  }else{
    return request({
      url: `/manage/admin/regional-manager/add`,
      method: 'post',
      params,
    });
  }
  
}

// 删除用户权限信息
export function deleteUserLimit(user_id) {
  return request({
    url: `/manage/admin/regional-manager/remove/${user_id}`,
    method: 'delete',
    params: {
      user_id : user_id,
    },
  });
}

// 权限范围列表(大小区机构列表)
export function getLimitList() {
    return request({
      url: `/manage/app/select/getAreaOrg`,
      method: 'get',
    });
  }