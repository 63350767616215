<template>
  <a-modal :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :width="800"
           :centered="true"
           :footer="null"
           wrap-class-name="create-code-modal"
           :after-close="afterClosed"
           @cancel="close()">
    <div class="flex container">
      <a-form-model ref="formRef"
                    :model="form"
                    :rules="rules"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 18}"
                    label-align="right"
                    class="form-model flex-grow mr-30"
                    @submit="onSubmit">
        <a-form-model-item label="手机号/身份证号" prop="title" class="form-item">
          <a-input v-model="form.username_or_idcard"
                   :allow-clear="true"
                   :disabled="user_id"
                   placeholder="请填写手机号/身份证号"/>
        </a-form-model-item>
        <a-form-model-item label="权限范围" prop="limit" class="form-item">
          <a-select v-model="form.org_ids" :allow-clear="true" :show-search="true"
              placeholder="请选择权限范围"
              mode="multiple" 
              :filter-option="filterOption" @change="select">
            <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{span: 16, offset: 8}" class="mb-10">
          <a-button type="primary"
                    :loading="loading"
                    @click="onSubmit">
            提交
          </a-button>
          <a-button @click="close">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import {
  getUserLimitInfo,
  saveUserLimitInfo,
} from '../api';

export default {
  name: 'CreateLimitModal',
  computed: {
    rules() {
      return {
        username_or_idcard: [{ required: true, trigger: 'blur', message: '请填写手机号/身份证号' }],
        org_ids: [{ required: true, trigger: 'change', message: '请选择权限类型' }],
      };
    },
  },
  watch: {
    
  },
  data() {
    return {
      shown: false,
      loading: false,
      user_id: '',
      form: {
        username_or_idcard: '',
        org_ids: [],
      },
      options: [],
    };
  },
  methods: {
    show({ user_id, options } = {}) {
      this.user_id = user_id;
      this.options = options;
      this.shown = true;
      this.getUserLimitInfo();
    },
    close(evt) {
      this.$emit('closed', evt);
      this.shown = false;
    },
    afterClosed() {
      this.user_id = '';
      this.form = {
        username_or_idcard: '',
        org_ids: [],
      };
    },
    filterOption(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) > -1;
    },
    select() {
        // console.log('选择', this.form);
    },
    async getUserLimitInfo() {
      if (!this.user_id || this.loading) {
        return;
      }
      this.loading = true;
      const data = await getUserLimitInfo(this.user_id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取信息失败');
        return;
      }
      const result = data?.data || {};
      this.form = {
        username_or_idcard: result.username || '',
        org_ids: result.org_id_arr || [],
      };
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((validated) => {
          if (validated) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    },
    onSubmit() {
      this.validate().then(() => {
        this.onSave();
      });
    },
    async onSave() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = {
        username_or_idcard: this.form.username_or_idcard || '',
        org_ids: this.form.org_ids.join(',') || '',
        user_id: this.user_id || '',
      };
      console.log(params);
      const data = await saveUserLimitInfo(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        this.loading = false;
        return;
      }
      this.$message.success(data.message || '保存成功');

      this.close({ all: !this.user_id, item: params });
    },
    
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: 85vw;
  max-height: 85vh;
}

.form-model {
  width: 500px;
  flex: none !important;
}

.form-item {
  margin-bottom: 5px;
  ::v-deep .input {
    min-width: 240px !important;
  }
}

</style>
